import * as fa6 from "react-icons/fa6";
import * as gr from "react-icons/gr";
import * as ci from "react-icons/ci";
import * as go from "react-icons/go";
import * as fa from "react-icons/fa";
import * as ai from "react-icons/ai";
import * as bi from "react-icons/bi";
import * as bs from "react-icons/bs";
import * as gi from "react-icons/gi";
import * as io5 from "react-icons/io5";
import * as si from "react-icons/si";
import * as pi from "react-icons/pi";
import * as tb from "react-icons/tb";
import * as md from "react-icons/md";
import * as io from "react-icons/io";
import * as ri from "react-icons/ri";

interface LibType {
    [keys: string]: Object;
}

const lib: LibType = {
    fa6,
    fa,
    io,
    ai,
    bi,
    bs,
    md,
    gi,
    io5,
    si,
    pi,
    tb,
    go,
    ri,
    gr,
    ci,
};

export default lib;
