const themes = [
    {
        default: {
            primary: "#1c1917",
            secondary: "#ffffff",

            accent: "#6b7280",
            neutral: "#6b7280",

            "base-100": "#ffffff",

            info: "#60a5fa",
            success: "#84cc16",
            warning: "#eab308",
            error: "#dc2626",

            "--rounded-box": "1rem", // Used in card and other large boxes
            "--rounded-btn": "0.5rem", // Border radius rounded-btn utility class
            "--rounded-badge": "1.9rem", // Used in badges and similar

            "--animation-btn": "0.25s", // Duration of animation when you click on button
            "--animation-input": "0.2s", // Duration of animation for inputs like checkbox, toggle, radio, etc

            "--btn-focus-scale": "0.95", // Scale transform of button when you focus on it
        },
    },

    "light",
    "dark",
    "cupcake",
    "bumblebee",
    "emerald",
    "corporate",
    "synthwave",
    "retro",
    "cyberpunk",
    "valentine",
    "halloween",
    "garden",
    "forest",
    "aqua",
    "lofi",
    "pastel",
    "fantasy",
    "wireframe",
    "black",
    "luxury",
    "dracula",
    "cmyk",
    "autumn",
    "business",
    "acid",
    "lemonade",
    "night",
    "coffee",
    "winter",
    "dim",
    "nord",
    "sunset",
];

export default themes;
